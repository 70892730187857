/* 
@font-face {
  font-family: AltoneTrial;
  src: url(/public/AltoneTrial-Regular.ttf);
} */


body {
  background-image: url('/public/images/background3.png');
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  background-size: contain;
}
.container1{
  background-image: url('/public/images/background2.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-bottom: 58.44%;
}

.container2 {
  background-image: url('/public/images/background3.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
}
.logo {
  width: 100%;
  max-width: 190px!important;
}
.steezy-ape-gang-hash-tag {
  width: 180px;
}
.btn-primary {
  background-color: #eb3496!important;    
  border-radius: 50px!important;
  font-weight: bold!important;
  font-size: 25px!important;
}


@media only screen
and (min-width : 768px) {

  .btn-primary {
    width: 250px!important;
    height: 75px!important;
  }
  


  .footer {
    color: #eb3496;
    font-size:15px;
  }


  
}
.btn-primary {
  width: 180px!important;
  font-size: 18px!important;
  height: 50px!important;
}
.container-fluid {
  max-width: 1900px!important;
}

.footer {
  color: #eb3496;
  font-size:25px;
}

.footer img {
  width: 150px;
}

.footer img.signature {
  width:100px;
}

.total {
  font-size:100px;
}

.social-icon {
  max-width: 50px;
}


h5 {
  font-size:20px!important;
}